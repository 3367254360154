<template>
  <v-container fill-height grid-list-md>
    <v-layout align-star  row fill-height>

      <v-card-title primary-title>
        Productos Relacionados clave: {{ getArt.numart }} linea: {{ getArt.linea }}

      </v-card-title>

      <v-flex xs12 >
        <v-card class="elevation-0 transparent">
          <v-card-title primary-title>
            PRODUCTOS RELACIONADOS

          </v-card-title>
        </v-card>
      </v-flex>

      <v-flex xl2 lg3 md3 sm3 xs6

        v-for="(artsxli, i) in getArticulosxLinea"
        :key="i"
        >
        <v-hover>
          <v-card
            height="100%"
            class="ma-2"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            @click="info(artsxli.Numart)"
            >

            <v-layout align-start justify-center row wrap>
              <v-flex xs12 class="elevation-0">
                <v-layout row wrap>
                  <v-flex xs12>

                        <v-container width="100%">
                          <v-img v-if="artsxli.Url == ''" src="@/assets/blanco.jpg" alt="Sin imagen"></v-img>
                          <v-img v-else :src="artsxli.Url" alt="Sin  imagen"></v-img>
                        </v-container>

                  </v-flex>
                </v-layout>
                <v-card-text class="py-0 ma-0">

                  <v-list-item-content class=" text--darken-2" >

                    <h4>{{ artsxli.Descrip }}</h4>

                </v-list-item-content>
                </v-card-text>

              </v-flex>
            </v-layout>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    	articulos: [],
    Linea: '',
    NumArt: '',
    show: false,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red'
    ]
  }),

  computed: {
    ...mapGetters('carrito', ['getTC', 'getCarrito']),
    ...mapActions('articulos', ['verArt', 'traerArticulosxLinea']),

    ...mapGetters('articulos', ['getArt', 'getArticulosxLinea']),
    ...mapGetters('Login', ['getModo'])
  },

  // mounted(){
  //   this.articulos = this.getArticulosxLinea
  // },

  created () {
    // console.log(this.getArticulosxLinea[0].Numart)
    // console.log(this.getArt.linea)
    // console.log(this.getArticulosxLinea)
    // // console.log(this.getArt)
    // this.Linea = this.getArt.linea
    // this.Numart= this.getArt.numart

    // console.log(this.getArticulosxLinea)

    // promesaLinea.then(this.init(), console.log("errror"));

    this.init()
  },

  // watch:{
  //   $route(){
  //     this.init()
  //   }
  // },

  methods: {

    init () {
      this.traerArticulosxLinea(this.getArt.linea).then(respuesta => {
        console.log('RELACIONADOS')
        console.log(respuesta[0].numart)
        this.articulos = respuesta
      }).catch(err => { consol.log(err) })

      // this.$http.get('api/v1/arts.bylinea/' + this.getArt.Linea).then(response=>{
      //   console.log("bylinea",response.body)

      //     this.response.data.forEach((element) => {
      //       this.articulos.push(element)
      //     })

      //   }).catch (err=> {
      //     console.log(err)
      //   })

      // var payload = {  BuscAart : numart}
      // console.log("payload", payload)

      // this.$http.post('api/v1/product.find.list',payload).then(response=>{
      //   console.log(response.data)
      // 	var linea = response.body.Productos[0].Linea

      //   this.$http.get('api/v1/arts.bylinea/' + linea).then(response=>{
      //     for (var i = 0; i >= 6; i--) {
      //       this.articulos.push(response.body[i])
      //     }
      //   })
      // })
    },

    info (numart) {
      var NumArt = numart.trim()
      this.$router.push({ name: 'verart', params: { numart: NumArt } })
    },

    ver () {
      this.$router.push({ name: 'verart' })
    }

  }

}
</script>
